export enum ENERGY_FUEL_TYPES {
    oil = 'oil',
    gas = 'gas',
    lpg = 'lpg',
    electricity = 'electricity',
    pellets = 'pellets', // RSN only
}

export enum HEATING_SYSTEM_TYPE {
    heatpump = 'heatpump',
    hybrid = 'hybrid',
    boiler = 'boiler',
}

export enum UNIT_TRANSLATION_TYPE {
    DONT_TRANSLATE = 'dont-translate',
    INDOOR_TO_BOILER = 'indoor-to-boiler',
    FIRST_INDOOR_SECOND_BOILER = 'first-indoor-second-boiler',
    FIRST_INDOOR_SECOND_OPTION = 'first-indoor-second-option',
    ALL_TO_OPTION = 'all-to-options',
}

export enum GROUND_LOOP_SYSTEMS {
    VERTICAL = 'Vertical',
    HORIZONTAL = 'Horizontal',
}

// heating & cooling
export enum ENERGY_LABELS {
    aPlusPlusPlus = 'a-plus-plus-plus',
    aPlusPlus = 'a-plus-plus',
    aPlus = 'a-plus',
    a = 'a',
    b = 'b',
    c = 'c',
    d = 'd',
}

export enum DHW_ENERGY_LABELS {
    aPlus = 'a-plus',
    a = 'a',
    b = 'b',
    c = 'c',
    d = 'd',
    e = 'e',
    f = 'f',
}

export type DhwEnergyLabelTypes = `${DHW_ENERGY_LABELS}`;

export enum DEVICE_TYPE {
    mobile = 'mobile',
    tablet = 'tablet',
    desktop = 'desktop',
}

export enum SALUTATIONS {
    F = 'mrs',
    M = 'mr',
}

export enum AIR_TO_AIR_TYPES {
    winter_heating = 'winter_heating',
    summer_cooling = 'summer_cooling',
}

export enum SPLIT_PRICE_TYPE {
    dont_show = 'dont_show',
    show_total_price = 'show_total_price',
    show_split_prices = 'show_split_prices',
    show_equipment_only = 'show_equipment_only',
}

export enum ALTHERMA_TYPE {
    smart = 'smart',
    regular = 'regular',
}

export enum COLOR_VARIATIONS {
    W = 'W', // White
    B = 'B', // Black
    S = 'S', // Silver
    T = 'T', // Blackwood
}

export enum COLOR_VARIATION_HEXES {
    W = '#dddee0',
    B = '#000000',
    S = '#666a6d',
    T = '#393e38',
}

export enum COLOR_VARIATION_LABELS {
    W = 'color_white',
    B = 'color_black',
    S = 'color_silver',
    T = 'color_blackwood',
}

export enum UK_INCENTIVE_TYPES {
    bus = 'bus',
    hes = 'hes',
    nidirect = 'nidirect',
    no_incentives = 'no_incentives',
}

export enum HYBRID_TYPE {
    economical = 'economical',
    ecological = 'ecological',
}
