import { SPLIT_PRICE_TYPE } from '@common/types';
import { TsBoolean } from '@tsUtils';
import {
    AirToAirLead,
    AtaMultiRoomUnitData,
    LeadRoom,
    SingleRoomSolution,
    SizingAtaMultiRoomRoomData,
    Store,
} from '../../types';
import { getShowPrices } from './genericSelectors';
import { getCommercePrices } from './leadSelectors';

export const getSingleRoomSolution = (
    state: Store,
    solutionId: string,
): SingleRoomSolution | undefined => {
    return state.settings.singleRoomSolutions?.find((s) => s.id === solutionId);
};

export const getTotalMultiRoomPrice = (state: Store): number => {
    let totalPrice = 0;
    const rooms = state.roomBuilder;
    const multiRoomSolution = state.settings.multiRoomSolutions?.find(
        (mss) => mss.numberOfRooms === rooms.length,
    );
    totalPrice += multiRoomSolution?.fromPrice || 0;
    rooms.forEach((r) => {
        if (r.solution) {
            const solution = getSingleRoomSolution(state, r.solution);
            if (solution?.fromPriceIndoorUnit) {
                totalPrice += solution.fromPriceIndoorUnit!;
            }
        }
    });
    return parseFloat(totalPrice.toFixed(2));
};

export const getTotalSurface = (state: Store): number => {
    return state.roomBuilder.reduce((total, room) => total + (room.surface || 0), 0);
};

export const getShowSplitPrices = (state: Store): boolean => {
    const { pricingDisplayStrategy: priceStrat } = state.settings.solutionPricing;
    if (state.appState.commerceError) return false;
    return getShowPrices(state) && priceStrat === SPLIT_PRICE_TYPE.show_split_prices;
};

export const getShowUnitPrices = (state: Store): boolean => {
    const { pricingDisplayStrategy: priceStrat } = state.settings.solutionPricing;
    if (state.appState.commerceError) return false;
    return getShowPrices(state) && priceStrat !== SPLIT_PRICE_TYPE.show_total_price;
};

export const getShowTotalPrice = (state: Store): boolean => {
    const { pricingDisplayStrategy: priceStrat } = state.settings.solutionPricing;
    if (state.appState.commerceError) return false;
    return getShowPrices(state) && priceStrat !== SPLIT_PRICE_TYPE.show_equipment_only;
};

type IndoorSolutionRowData = Omit<SizingAtaMultiRoomRoomData, 'size' | 'indoor'> & {
    unit: AtaMultiRoomUnitData | null;
    price: string | null;
};

export type RoomsDataPerSolution = {
    solutionId: string | undefined;
    solution: SingleRoomSolution | null;
    data: Array<IndoorSolutionRowData>;
    showPrices: boolean;
};

export const getRoomsDataPerSolution = (state: Store): Array<RoomsDataPerSolution> => {
    // Get rooms from lead
    const { rooms } = state.lead as AirToAirLead;
    // Get sizing data from sizing tool
    const sizingMultiRoom = state.lead.toolData?.sizingMultiRoom;
    // Create a set of unique solutionIds from the rooms on the lead
    const solutionIds = Array.from(new Set<string>(rooms?.map((room) => room.solution)));
    // Get the solutions from the settings that are linked to the rooms
    const settingSolutions = solutionIds
        .map((solutionId) => getSingleRoomSolution(state, solutionId))
        .filter(TsBoolean);

    const prices = getCommercePrices(state);
    const showUnitPrices = getShowUnitPrices(state);

    // Map room indexes for each solution in object
    type RoomIndexesPerSolution = Record<string, Array<number>>;
    const roomIndexesPerSolution = rooms!.reduce((obj: RoomIndexesPerSolution, room: LeadRoom) => {
        const { solution, position } = room;
        if (obj[solution]) return { ...obj, [solution]: [...obj[solution], position] };
        return { ...obj, [solution]: [position] };
    }, {} as RoomIndexesPerSolution);

    // Loop over the setting solutions and create a object
    return settingSolutions.map((settingSolution) => {
        const roomIndexes = roomIndexesPerSolution[settingSolution.id];
        const data: Array<IndoorSolutionRowData> = [];
        // Loop over the room indexes
        roomIndexes?.forEach((roomIndex) => {
            // Filter the sizing Room data for the room with the room index
            const sizingRoomData = sizingMultiRoom?.rooms?.filter((r) => r.id === roomIndex);

            if (sizingRoomData) {
                // Loop over the sizing Room data and create a data object
                sizingRoomData.forEach((srd) => {
                    data.push({
                        name: srd.name,
                        id: srd.id,
                        unit: srd.indoor,
                        price: prices?.units?.[srd.indoor['product-name']] || null,
                    });
                });
            }
        });

        return {
            solutionId: settingSolution?.id,
            solution: settingSolution,
            data,
            showPrices: showUnitPrices,
        };
    });
};
