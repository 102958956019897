import { INCENTIVES_STATUS } from '../../constants';
import { IncentivesData } from '../../types';
import { IncentiveActionsType, INCENTIVES_DE, INCENTIVES_DRAPO } from '../actions';

const initialState: Partial<IncentivesData> = {
    status: INCENTIVES_STATUS.in_progress,
    toolData: {},
};

const incentives = (
    state: Partial<IncentivesData> | null,
    action: IncentiveActionsType,
): Partial<IncentivesData> | null => {
    state = state || initialState;
    switch (action.type) {
        // -- 🇫🇷 France 🇫🇷 --
        case INCENTIVES_DRAPO.setAmountOfPeople:
            return {
                ...state,
                toolData: {
                    ...state.toolData,
                    incentivesFR: {
                        ...state.toolData?.incentivesFR,
                        amountOfPeople: action.amountOfPeople,
                    },
                },
            };
        case INCENTIVES_DRAPO.setIncomeRanges:
            return {
                ...state,
                toolData: {
                    ...state.toolData,
                    incentivesFR: {
                        ...state.toolData?.incentivesFR,
                        incomeRanges: action.incomeRanges,
                        income: action.incomeRanges[0].value, // Always select lowest value first
                    },
                },
            };
        case INCENTIVES_DRAPO.setDrapoData:
            return {
                ...state,
                toolData: {
                    ...state.toolData,
                    incentivesFR: {
                        ...state.toolData?.incentivesFR,
                        ...action.data,
                    },
                },
            };
        // -- 🇩🇪 Germany 🇩🇪 --
        case INCENTIVES_DE.update:
        case INCENTIVES_DE.done:
            return {
                ...state,
                toolData: {
                    ...state.toolData,
                    incentivesDE: {
                        ...action.data,
                    },
                },
            };
        // -- Default --
        default:
            return state;
    }
};

export default incentives;
