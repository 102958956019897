/* eslint-disable camelcase */

export enum APP_STATUS {
    loading = 'loading',
    // AirToWater SFT
    sft_he_project_type = 'sft_he_project_type',
    sft_he_function_type = 'sft_he_function_type',
    sft_he_household_composition = 'sft_he_household_composition',
    sft_he_emitter_type = 'sft_he_emitter_type',
    sft_he_previous_heating_system = 'sft_he_previous_heating_system',
    sft_he_building_type = 'sft_he_building_type',
    sft_he_preferred_solution = 'sft_he_preferred_solution',
    // AirToAir SFT
    sft_ra_air_to_air_type = 'sft_ra_air_to_air_type',
    sft_ra_room_type = 'sft_ra_room_type',
    sft_ra_number_of_rooms = 'sft_ra_number_of_rooms',
    sft_ra_positioning_type = 'sft_ra_positioning_type',
    sft_ra_room_size = 'sft_ra_room_size',
    sft_ra_house_size = 'sft_ra_house_size',
    sft_ra_preferred_solution = 'sft_ra_preferred_solution',
    sft_ra_unsupported_positioning_type = 'sft_ra_unsupported_positioning_type',
    sft_ra_room_builder = 'sft_ra_room_builder', // multi-split
    // Step 2
    sol_view_solution = 'sol_view_solution',
    // Step 3
    sp_sizing_tool = 'sp_sizing_tool',
    sp_quotation_tool = 'sp_quotation_tool',
    sp_selsoft_result = 'sp_selsoft_result',
    // Step 4
    incentives_tool = 'incentives_tool',
    // Step 5
    dealer_selection = 'dealer_selection',
    // Finished
    finish_lead = 'finish_lead',
}

export const appStatusEventLabels: Record<string, string> = {
    // AirToWater SFT
    [APP_STATUS.sft_he_project_type]: 'projectType_screen',
    [APP_STATUS.sft_he_function_type]: 'functionType_screen',
    [APP_STATUS.sft_he_household_composition]: 'waterCalculation_screen',
    [APP_STATUS.sft_he_emitter_type]: 'emitterType_screen',
    [APP_STATUS.sft_he_previous_heating_system]: 'previousHeatingSystem_screen',
    [APP_STATUS.sft_he_building_type]: 'buildingType_screen',
    [APP_STATUS.sft_he_preferred_solution]: 'productSelection_screen',
    // AirToAir SFT
    [APP_STATUS.sft_ra_air_to_air_type]: 'interest_screen',
    [APP_STATUS.sft_ra_room_type]: 'roomType_screen',
    [APP_STATUS.sft_ra_number_of_rooms]: 'roomNumber_screen',
    [APP_STATUS.sft_ra_positioning_type]: 'unitType_screen',
    [APP_STATUS.sft_ra_room_size]: 'roomSize_screen',
    [APP_STATUS.sft_ra_preferred_solution]: 'productSelection_screen',
    // Step 2
    [APP_STATUS.sol_view_solution]: 'configure_solution_screen',
    // Step 3
    // AirToWater
    'has-epc': 'epcRating_screen',
    'epc-rating': 'epcConsumption_screen',
    'house-type': 'houseType_screen',
    'adjoining-type': 'adjoiningType_screen',
    'heated-surface': 'surfaceCalculation_screen',
    'house-constructed': 'constructionYear_screen',
    'know-energy-consumption': 'knowEnergyConsumption_screen',
    'energy-consumption': 'yearlyConsumption_screen',
    'energy-source': 'heatingEnergySource_screen',
    'generator-type': 'generatorType_screen',
    // AirToAir
    'window-size': 'windowSize_screen',
    orientation: 'dayTime_screen',
    'window-shading': 'windowShading_screen',
    'room-height': 'roomHeight_screen',
    'room-floor': 'floor_screen',
    'house-built': 'constructionYear_screen',
    'heating-generator-type': 'heatingGeneratorType_screen',
    'heating-source': 'heatingEnergySource_screen',
    'heating-consumption': 'yearlyEnergyConsumption_screen',
    'energy-cost': 'energyCosts_screen',
    'domestic-hot-water': 'knowHotWaterConsumption_screen',
    'house-people': 'peopleNumber_screen',
    'family-home-type': 'buildingType_screen',
    'apartment-size': 'apartmentNumber_screen',
    // COMMON
    'postal-code': 'postalCode_screen',
    'units-to-install': 'configure_result_screen',
    'unit-placement-$indoor': 'indoorUnitPlacement_screen',
    'unit-placement-$outdoor': 'outdoorUnitPlacement_screen',
    'unit-placement-$tank': 'tankUnitPlacement_screen',
    'pipe-length': 'pipeLength_screen',
    controls: 'thermostat_screen',
    'quotation-overview': 'configure_confirmation_screen',
    [APP_STATUS.sp_selsoft_result]: 'configure_incentive_screen',
    // Step 4
    [APP_STATUS.incentives_tool]: 'configure_quotation_screen',
    // Step 4: incentives DE
    'no-house-owner': 'noHouseOwner_screen',
    'house-owner': 'houseOwner_screen',
    'work-type': 'workType_screen',
    'boiler-type': 'boilerType_screen',
    'isfp-bonus': 'isfpBonus_screen',
    'efficiency-class': 'efficiencyClass_screen',
    'is-passive': 'passiveHouse_screen',
    'renovation-type': 'renovationType_screen',
    // Step 5
    [APP_STATUS.dealer_selection]: 'dealer_screen',
    // Finished
    [APP_STATUS.finish_lead]: 'request_quotation_screen',
};

export enum RSN_SECTION {
    loading = 0,
    section_1_solution_finder_tree = 1,
    section_2_solution_overview = 2,
    section_3_sizing_and_pricing = 3,
    section_4_incentives = 4,
    section_5_dealer_selection = 5,
    finished = 6,
}

export const eventActions: Record<number, string> = {
    [RSN_SECTION.section_1_solution_finder_tree]: 'select',
    [RSN_SECTION.section_2_solution_overview]: 'solution',
    [RSN_SECTION.section_3_sizing_and_pricing]: 'sizePricing',
    [RSN_SECTION.section_4_incentives]: 'incentive',
    [RSN_SECTION.section_5_dealer_selection]: 'dealerSelection',
};

export enum PROXY_TYPES {
    incomeRange = 'income-range',
    geolocation = 'geo-location',
    selsoft = 'selection-software',
    dealer = 'dealer',
    literature = 'literature',
    energyLabels = 'energy-labels',
}

export enum MODAL_TYPE {
    spin360 = 'spin360',
    sftImageViewer = 'sftImageViewer',
    imageGallery = 'imageGallery',
    videoPlayer = 'videoPlayer',
    althermaToggle = 'althermaToggle',
    info = 'info',
    contactADealer = 'contactADealer',
    intrusive = 'intrusive',
    evsAtwCompare = 'evsAtwCompare',
    evsAtaHeatingCompare = 'evsAtaHeatingCompare',
    evsCalculationDisclaimer = 'evsCalculationDisclaimer',
    selectRooms = 'selectRooms',
    indoorSolutionInfo = 'indoorSolutionInfo',
    energyPrices = 'energyPrices',
}

export enum DETACHED_SCREEN_TYPE {
    account = 'account',
    leadCompare = 'leadCompare',
    unlinkedLead = 'unlinkedLead',
    versionExpired = 'versionExpired',
    logIn = 'logIn',
    loggedOut = 'loggedOut',
    althermaChoice = 'althermaChoice',
    videoPlayer = 'videoPlayer',
    aemRedirectCCU = 'aemRedirectCCU',
    aemRedirectMultiRoom = 'aemRedirectMultiRoom',
}

export enum SCRIPT_STATE {
    injected = 'injected',
    error = 'error',
}

export enum TRANSITION {
    // AirToWater SFT
    sft_he_choose_project_type = 'sft_he_choose_project_type',
    sft_he_choose_function_type = 'sft_he_choose_function_type',
    sft_he_choose_household_composition = 'sft_he_choose_household_composition',
    sft_he_choose_emitter_type = 'sft_he_choose_emitter_type',
    sft_he_choose_previous_heating_system = 'sft_he_choose_previous_heating_system',
    sft_he_choose_building_type = 'sft_he_choose_building_type',
    sft_he_choose_preferred_solution = 'sft_he_choose_preferred_solution',
    // AirToAir SFT
    sft_ra_choose_air_to_air_type = 'sft_ra_choose_air_to_air_type',
    sft_ra_choose_room_type = 'sft_ra_choose_room_type',
    sft_ra_choose_number_of_rooms = 'sft_ra_choose_number_of_rooms',
    sft_ra_choose_positioning_type = 'sft_ra_choose_positioning_type',
    sft_ra_choose_unsupported_positioning_type = 'sft_ra_choose_unsupported_positioning_type',
    sft_ra_choose_room_size = 'sft_ra_choose_room_size',
    sft_ra_choose_preferred_solution = 'sft_ra_choose_preferred_solution',
    sft_ra_room_builder = 'sft_ra_room_builder', // multi-split
    // Step 2
    sft_he_view_solution = 'sft_he_view_solution',
    sft_ra_view_solution = 'sft_ra_view_solution',
    // Sizing & pricing (step 3)
    view_sizing_tool = 'sp_sizing_tool',
    view_quotation_tool = 'sp_quotation_tool',
    view_selsoft_result = 'sp_selsoft_result',
    // Incentives (step 4)
    view_incentives_tool = 'incentives_tool',
    // Dealer selection (step 5)
    view_dealer_selection = 'dealer_selection',
}

export enum ENERGY_TYPES {
    'oil' = 'oil',
    'gas' = 'gas',
    'lpg' = 'lpg',
    'electricity' = 'electricity',
    'pellets' = 'pellets',
}

export enum HOUSE_ADJOINING_TYPE {
    detached = 'detached',
    semiDetached = 'semiDetached',
    midTerrace = 'midTerrace',
    // Duplicated because Aaltra (DAI001-8129)
    mid_Terrace = 'mid-terrace',
    semi_detached = 'semi-detached',
    apartment = 'apartment', // Only for Quotation
}

// For Italy (new parameters added DAI001-8129)
export enum APARTMENT_LOCATION {
    midAptNotTop = 'midAptNotTop',
    cornerAptNotTop = 'cornerAptNotTop',
    midAptTopAttic = 'midAptTopAttic',
    cornerAptTopAttic = 'cornerAptTopAttic',
    midAptTopRoof = 'midAptTopRoof',
    cornerAptTopRoof = 'cornerAptTopRoof',
}

export enum HOUSE_TYPE {
    bungalow = 'bungalow',
    attics = 'attics',
    floors = 'floors',
}

export enum RENOVATION_TYPE {
    noRenovation = 'noRenovation',
    everything = 'everything',
    roofAndExteriorFacade = 'roofAndExteriorFacade',
    windowsAndExteriorFacade = 'windowsAndExteriorFacade',
    windowsAndRoof = 'windowsAndRoof',
    onlyExteriorFacade = 'onlyExteriorFacade',
    onlyRoof = 'onlyRoof',
    onlyWindows = 'onlyWindows',
}

export enum APARTMENT_SIZE {
    oneToTwoApartments = 'oneToTwoApartments',
    threeToFiveApartments = 'threeToFiveApartments',
    moreThanSixApartments = 'moreThanSixApartments',
}

/* AirToWater types */

export enum PROJECT_TYPE {
    new = 'new',
    renovation = 'renovation',
}
export enum FUNCTION_TYPE {
    heating = 'heating',
    heating_dhw = 'heating_dhw',
    heating_cooling = 'heating_cooling',
    heating_cooling_dhw = 'heating_cooling_dhw',
}
export enum SOLUTION_TYPE {
    heating = 'heating',
    room_airco = 'room_airco',
}
export enum EMITTER_TYPE {
    ufh = 'ufh',
    rad = 'rad',
    hpc = 'hpc',
    ufh_rad = 'ufh_rad',
    ufh_hpc = 'ufh_hpc',
}
export enum BUILDING_TYPE {
    sfh = 'sfh',
    mfh = 'mfh',
}
export enum PHASING_TYPE {
    single_phase = 'single_phase',
    three_phase = 'three_phase',
}
// Translation map for SBM phasing -> SelSoft phasing
export const phasingMap = {
    [PHASING_TYPE.single_phase]: '1',
    [PHASING_TYPE.three_phase]: '3N',
};

export enum BOILER_TYPE {
    gas = 'gas',
    oil = 'oil',
    other = 'other',
}
export enum INTRUSIVE_TYPE {
    low = 'low',
    minor = 'minor',
    medium = 'medium',
}

export enum UNIT_PLACEMENT_ROOM {
    garage = 'garage',
    laundry = 'laundry',
    attic = 'attic',
    kitchen = 'kitchen',
    basement = 'basement',
}

export enum CONSTUCTION_YEAR {
    by1970 = 'ConstructionYearBy1970',
    between1971And1990 = 'ConstructionYearBetween1971And1990',
    between1991And2005 = 'ConstructionYearBetween1991And2005',
    after2005 = 'ConstructionYearAfter2005',
    // New values for Germany (DAI001-8129)
    before1959 = 'ConstructionYearBefore1959',
    bBetween1959And1968 = 'ConstructionYearBetween1959And1968',
    between1969And1977 = 'ConstructionYearBetween1969And1977',
    between1978And1983 = 'ConstructionYearBetween1978And1983',
    between1984And1994 = 'ConstructionYearBetween1984And1994',
    between1995And2001 = 'ConstructionYearBetween1995And2001',
    between2002And2008 = 'ConstructionYearBetween2002And2008',
    between2009And2015 = 'ConstructionYearBetween2009And2015',
}

export enum POSITIONING_TYPE {
    wall_mounted = 'wall_mounted',
    floor_standing = 'floor_standing',
    concealed_ceiling_unit = 'concealed_ceiling_unit',
}

/* AirToAir types */

export enum UNIT_PLACEMENT_OUTDOOR { // is used by cooling-quotation and heating-quotation
    groundAway = 'ground-away',
    groundNear = 'ground-near',
    roof = 'roof',
}
export enum UNIT_PLACEMENT_INDOOR {
    internal = 'internal',
    external = 'external',
}
export enum UNIT_PLACEMENT_WALL {
    wallLow = 'wall-low',
    wallHigh = 'wall-high',
}

export enum ROOM_TYPE {
    single = 'single',
    multi = 'multi',
}

export enum ROOM_SIZE_GROUP {
    up_to = 'up_to',
    max = 'max',
}
export enum CONNECTIVITY {
    standard = 'standard',
    optional = 'optional',
}

export enum DEALER_SCHED_FACTOR {
    less_than_1_month = 'Less than 1 month',
    between_1_or_3_months = 'Between 1 or 3 months',
    more_than_3_months = 'More than 3 months',
}

export enum ENERGY_SOURCES {
    air = 'air',
    gas = 'gas',
    ground = 'ground',
    oil = 'oil',
}

/* Incentive types */

export enum INCENTIVES_STATUS {
    in_progress = 'in_progress',
    completed = 'completed',
    failed = 'failed',
}

export enum DRAPO_OWNER_TYPES {
    owner = 'owner',
    landlord = 'landlord-owner',
    other = 'other',
}

/* Lead Compare */
// -i- Cell Fallback:
// -i- Change this to '' to hide empty rows & sections where the selector was not able to find any lead values
// -i- Use truthy string instead to show otherwise hidden/empty/faulty selector rows
export const CELL_FB = '';

export enum EVS_OVERVIEW_TAB {
    price = 'price',
    co2 = 'co2',
}

export enum ORIENTATION {
    north = 'N',
    north_east = 'NE',
    east = 'E',
    south_east = 'SE',
    south = 'S',
    south_west = 'SW',
    west = 'W',
    north_west = 'NW',
}

export enum SHADING {
    shutters = 'outside',
    blinds_curtains = 'inside',
    sun_protected_glass = 'protecting',
    no_shading = 'no',
}
