import * as Logger from '@common/Logger';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { PROXY_TYPES } from '../../constants';
import { AtaEnergyLabelsData, AtwEnergyLabelsData, Store } from '../../types';
import {
    LEAD_ACTIONS,
    SetLeadLocalAction,
    fetchEnergyLabels,
    setEnergyLabels,
    setEnergyLabelsError,
} from '../actions';
import { getEnergyLabelParams, getGenericPayload, isAirToAirProject } from '../selectors';

type DepObj = { getState: () => Store };

type SetLeadLocalDepObj = DepObj & { action: SetLeadLocalAction };

const triggerFetchEnergyLabelsLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'energyLabelsLogic.triggerFetchEnergyLabels',
    process({ getState, action }: SetLeadLocalDepObj, dispatch, done) {
        // Only trigger fetch energy labels for atw leads
        if (isAirToAirProject(getState())) return done();
        // Trigger fetching energy labels again if phasing is changed
        if (action.logicOrigin !== 'phasingLogic') return done();
        // All checks passed, fetching energy labels
        dispatch(fetchEnergyLabels());
        return done();
    },
});

type EnergyLabelsResponseType = {
    responseData: AtwEnergyLabelsData | AtaEnergyLabelsData;
    success: boolean;
};
const fetchEnergyLabelsLogic = createLogic({
    type: LEAD_ACTIONS.fetchEnergyLabels,
    name: 'energyLabelsLogic.fetch',
    async process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        const { proxyCall } = state.settings.urls;
        const genericPayload = await getGenericPayload(state);
        const params = getEnergyLabelParams(state);

        const payload = { ...genericPayload, data: params, type: PROXY_TYPES.energyLabels };
        axios
            .post<EnergyLabelsResponseType>(proxyCall, payload)
            .then((res) => {
                const { data } = res;
                if (res.status === 200 && data?.success === true) {
                    dispatch(setEnergyLabels(data.responseData));
                }
            })
            .catch((reason) => {
                if (reason !== 'abort') Logger.error('fetchEnergyLabels failed.', reason);
                dispatch(setEnergyLabelsError('no_ecolabels_yet'));
            })
            .finally(done);
    },
});

export default [triggerFetchEnergyLabelsLogic, fetchEnergyLabelsLogic];
