import { COLOR_VARIATIONS, ENERGY_FUEL_TYPES } from '@common/types';
import { dashToSnake } from '@tsUtils';
import { ORIENTATION, POSITIONING_TYPE, PROJECT_TYPE, SHADING } from '../../../constants';
import {
    AirToAirLead,
    AirToAirSingleRoomSbmSolution,
    AirToWaterLead,
    HeatingCost,
    LongOrientation,
    PlatformDataSizingAtaCooling,
    PlatformDataSizingAtaHeating,
    PlatformDataSizingAtaMultiRoom,
    PlatformDataSizingAtw,
    Shading,
    SingleRoomSolution,
    Store,
    ToolsDataSizingAtaCooling,
    ToolsDataSizingAtaHeating,
    ToolsDataSizingAtaMultiRoom,
    ToolsDataSizingAtw,
} from '../../../types';
import { PlatformDataRoom } from '../../../types/external/sizing';
import {
    getHasDhw,
    isAirToAirHeatingLead,
    isAirToAirMultiRoomLead,
    isAirToWaterProject,
} from '../genericSelectors';

// Helper methods
const colorVariationMap = Object.freeze({
    [COLOR_VARIATIONS.W]: 'White',
    [COLOR_VARIATIONS.B]: 'Black',
    [COLOR_VARIATIONS.S]: 'Silver',
    [COLOR_VARIATIONS.T]: 'Blackwood',
});

const positioningTypeMap = Object.freeze({
    [POSITIONING_TYPE.wall_mounted]: 'Wall mounted',
    [POSITIONING_TYPE.floor_standing]: 'Floor standing',
    [POSITIONING_TYPE.concealed_ceiling_unit]: 'Concealed ceiling unit',
});

const projectTypeMap = Object.freeze({
    [PROJECT_TYPE.new]: 'new',
    [PROJECT_TYPE.renovation]: 'reno',
});

const filterColorVariation = (
    units: string[],
    colorVariation: string | undefined | null,
): string[] => {
    if (!colorVariation) return units;
    return units.filter((productName) => productName.split('').pop() === colorVariation);
};

// --- Platform JSON -----------------------------------------------------------

const getSizingAtwPlatformJson = (store: Store): PlatformDataSizingAtw => {
    const lead = store.lead as AirToWaterLead;
    const isReno = lead.projectType === PROJECT_TYPE.renovation;
    const hasDhw = getHasDhw(store.lead);
    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        construction: projectTypeMap[lead.projectType!],
        'consumption-source': isReno ? lead.previousHeatingSystem : null,
        'family-home-type': lead.buildingType!,
        adults: hasDhw ? lead.householdComposition!.adults : null,
        children: hasDhw ? lead.householdComposition!.children : null,
    };
};

const getSizingAtaCoolingPlatformJson = (store: Store): PlatformDataSizingAtaCooling => {
    const lead = store.lead as AirToAirLead;
    const { colorVariation, solution } = lead;
    const { indoorUnits, outdoorUnits } = solution! as AirToAirSingleRoomSbmSolution;

    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        'room-size': lead.roomSize,
        'indoor-possibilities': filterColorVariation(indoorUnits, colorVariation),
        'outdoor-possibilities': outdoorUnits,
    };
};

const getSizingAtaHeatingPlatformJson = (store: Store): PlatformDataSizingAtaHeating => {
    const lead = store.lead as AirToAirLead;
    const { colorVariation, solution } = lead;
    const { indoorUnits, outdoorUnits } = solution! as AirToAirSingleRoomSbmSolution;
    const { energyPrices } = store.settings;

    const heatingCost: Array<HeatingCost> = (
        Object.keys(energyPrices) as Array<ENERGY_FUEL_TYPES>
    ).map((key) => ({
        value: energyPrices[key],
        type: key,
    }));

    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        'room-surface': lead.roomSize,
        'house-surface': lead.houseSize,
        'product-range-name': lead.solution?.name,
        'indoor-possibilities': filterColorVariation(indoorUnits, colorVariation),
        'outdoor-possibilities': outdoorUnits,
        'heating-cost': heatingCost,
    };
};

const getSizingAtaMultiRoomPlatformJson = (state: Store): PlatformDataSizingAtaMultiRoom => {
    const lead = state.lead as AirToAirLead;
    const { country, language, singleRoomSolutions } = state.settings;

    const rooms = lead.rooms
        ? lead.rooms?.map((room, i) => {
              const solution = singleRoomSolutions!.find(
                  (s) => s.id === room.solution,
              ) as SingleRoomSolution;
              return {
                  name: room.name,
                  id: i + 1,
                  size: room.surface,
                  height: room.height,
                  'external-shading': SHADING[dashToSnake(room.shading) as Shading],
                  orientation: ORIENTATION[dashToSnake(room.orientation) as LongOrientation],
                  'window-size': room.windows,
                  color: room.colorVariation ? colorVariationMap[room.colorVariation] : null,
                  type: solution.chronosApiName,
                  application: positioningTypeMap[solution.positioningType],
              } as PlatformDataRoom;
          })
        : [];

    return {
        country: country.isoCode,
        language,
        rooms,
        'house-size': lead.houseSurface!,
        'dhw-included': false,
        'dhw-tank-size': null, // only filled in if dhw is true
        'number-of-people': 0,
    };
};

export const getSizingPlatformJson = (
    store: Store,
):
    | PlatformDataSizingAtw
    | PlatformDataSizingAtaCooling
    | PlatformDataSizingAtaHeating
    | PlatformDataSizingAtaMultiRoom => {
    if (isAirToWaterProject(store)) return getSizingAtwPlatformJson(store);
    else if (isAirToAirMultiRoomLead(store)) return getSizingAtaMultiRoomPlatformJson(store);
    else if (isAirToAirHeatingLead(store)) return getSizingAtaHeatingPlatformJson(store);
    else return getSizingAtaCoolingPlatformJson(store);
};

// --- Tools JSON --------------------------------------------------------------

const getSizingAtwToolsJson = (store: Store): ToolsDataSizingAtw | null => {
    const sizingHeating = store.lead.toolData?.sizingHeating;
    return sizingHeating ? { 'heating-sizing': sizingHeating } : null;
};

const getSizingAtaCoolingToolsJson = (store: Store): ToolsDataSizingAtaCooling | null => {
    const sizingCooling = store.lead.toolData?.sizingCooling;
    return sizingCooling ? { 'cooling-sizing': sizingCooling } : null;
};

const getSizingAtaHeatingToolsJson = (store: Store): ToolsDataSizingAtaHeating | null => {
    const sizingWinterHeating = store.lead.toolData?.sizingWinterHeating;
    return sizingWinterHeating ? { 'winter-heating-sizing': sizingWinterHeating } : null;
};

const getSizingAtaMultiRoomToolsJson = (store: Store): ToolsDataSizingAtaMultiRoom | null => {
    const sizingMultiRoom = store.lead.toolData?.sizingMultiRoom;
    return sizingMultiRoom ? { 'air-to-air': sizingMultiRoom } : null;
};

export const getSizingToolsJson = (
    store: Store,
):
    | ToolsDataSizingAtw
    | ToolsDataSizingAtaCooling
    | ToolsDataSizingAtaHeating
    | ToolsDataSizingAtaMultiRoom
    | null => {
    if (isAirToWaterProject(store)) return getSizingAtwToolsJson(store);
    else if (isAirToAirMultiRoomLead(store)) return getSizingAtaMultiRoomToolsJson(store);
    else if (isAirToAirHeatingLead(store)) return getSizingAtaHeatingToolsJson(store);
    else return getSizingAtaCoolingToolsJson(store);
};
